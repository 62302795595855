export const LIFESTYLE_TYPES = {
  HEALTH_CARE: 'Healthcare',
  GYM_AND_SPORTS: 'Gym and Sports',
  STUDENT_SOCIETY: 'Student society',
  STUDENT_HOUSING: 'Student housing',
  CAFE_AND_RESTAURANTS: 'Cafe and Restaurants',
  LIBRARIES_AND_BOOKSHOPS: 'Libraries and Bookshops',
  CLOTHING_AND_SHOES: 'Clothing and Shoes',
  HOUSEHOLD: 'Household stuff / In rooms',
  CULTURE: 'Culture',
  COPY_SHOPS: 'Copyshops',
  DRIVING_SCHOOLS: 'Driving schools',
  CINEMA: 'Cinema',
  MUNICIPALITY_AND_POLITICS: 'Municipality and Politics',
  BEAUTY_AND_HAIR: 'Beauty and Hair',
};
