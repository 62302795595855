import { size } from 'constants/device';

import { useState } from 'react';
import { observer } from 'mobx-react';
import { Divider } from '@studenten/ui-components';
import { LifestyleCard } from 'features/layout/components/Cards/LifestyleCard/LifestyleCard';
import SelectedFilters from 'features/search/components/SelectedFilters';
import { useStore } from 'features/search/models/lifestyleSearch';
import PageBanner from 'features/layout/components/PageBanner';
import SearchResultsHeader from 'features/search/components/SearchResultsHeader';
import MobileFiltersOpenButton from 'features/search/components/MobileFiltersOpenButton';
import { usePopRouterEventNotShallow } from 'features/search/hooks/usePopRouterEventNotShallow';
import SearchFilters from 'features/search/components/SearchFilters';
import { useWindowSize } from 'features/layout/hooks/useWindowSize';
import EmptySearch from 'features/search/components/EmptySearch';
import Pagination from 'features/search/components/Pagination';
import AutoSearch from 'features/layout/components/AutoSearch';
import Layout from 'features/layout/templates/Layout';
import {
  Page,
  MobileAutoSearchWrapper,
  FiltersAndResults,
  Results,
  ResultsList,
  ResultItem,
  ResultSection,
} from 'features/search/templates/Search.styled';
import { MetaTags } from 'lib/seo/MetaTags';

import { lifestyleFilters } from './filters';
import { Props } from './types';
import { getMetaTags } from './getMetaTags';

export const LifestyleSearch = observer(({ data, filters, entityDescription }: Props) => {
  usePopRouterEventNotShallow();
  const store = useStore(data, filters);
  const metaTags = getMetaTags(filters.type);
  const { width: windowWidth } = useWindowSize();
  const { totalCount, data: socientiesData } = store.data;
  const [isMobileFiltersOpen, setMobileFiltersOpen] = useState<boolean>(false);
  const isMobile = windowWidth < size.tablet;
  const withSearchResults = Boolean(totalCount);
  return (
    <>
      <MetaTags title={metaTags.title} description={metaTags.description} />
      <Layout>
        <Page>
          <PageBanner
            title={ entityDescription?.title || "Lifestyle"}
            description={entityDescription?.shortDescription || ""}
          />
          {isMobile && (
            <>
              <MobileAutoSearchWrapper>
                <AutoSearch />
              </MobileAutoSearchWrapper>
              <Divider />
            </>
          )}

          <Divider />
          <section id="searchResults">
            <SearchResultsHeader title="Lifestyle" totalCount={totalCount} />
            <MobileFiltersOpenButton
              onFilterOpen={() => setMobileFiltersOpen(true)}
              filters={store.filters}
            />
            <FiltersAndResults>
              <SearchFilters
                filters={lifestyleFilters}
                store={store}
                totalCount={totalCount}
                isOpen={isMobileFiltersOpen}
                setOpen={setMobileFiltersOpen}
              />

              <Results>
                <SelectedFilters
                  filters={store.filters}
                  handleDeleteFilter={store.deleteSelectedFilter.bind(store)}
                  handleDeleteAllFilters={store.deleteAllSelectedFilters.bind(store)}
                />
                <div>
                  {withSearchResults ? (
                    <ResultSection>
                      <ResultsList>
                        {socientiesData.map((props) => {
                          return (
                            <ResultItem key={props.objectID}>
                              <LifestyleCard horizontal={!isMobile} {...props} />
                            </ResultItem>
                          );
                        })}
                      </ResultsList>
                    </ResultSection>
                  ) : (
                    <EmptySearch />
                  )}
                </div>
                {withSearchResults && <Pagination totalCount={totalCount} />}
              </Results>
            </FiltersAndResults>
          </section>
        </Page>
      </Layout>
    </>
  );
});
