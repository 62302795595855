import { APP_ROUTES } from 'constants/routes';
import { CACHE_KEYS } from 'constants/cache';

import { useState, forwardRef, useRef, MouseEventHandler, KeyboardEventHandler, ChangeEventHandler } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import { Button, InputField, Icons } from '@studenten/ui-components';
import { useOutsideClick } from 'features/layout/hooks/useOutsideClick';
import { autocompleteApi } from 'api_entities/autocomplete';

import { Container, AutoComplete, IconSearchButtonWrapper } from './StyledAutoSearch';

const MIN_SYMBOLS = 2;

export const AutoSearch = forwardRef<HTMLInputElement>((_, ref) => {
  const [value, setValue] = useState<string>('');
  const [focused, setFocused] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const { data } = useQuery(
    [CACHE_KEYS.AUTOCOMPLETE, value],
    () => autocompleteApi.getSuggestions(value),
    {
      enabled: value.length >= MIN_SYMBOLS,
      refetchOnWindowFocus: false,
      keepPreviousData: value.length >= MIN_SYMBOLS,
    }
  );

  const isAutoCompleteShown = Boolean(focused && data?.length);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => setValue(e.target.value);

  const handleFocus = () => setFocused(true);

  const handleBlur = () => setFocused(false);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
      goToSearchPage();
    }
  };

  const handleClickClearInput: MouseEventHandler<HTMLButtonElement> = (e): void => {
    e.preventDefault();
    setValue('');
  };

  const goToSearchPage = () => {
    if (value) {
      router
        .push(`${APP_ROUTES.SEARCH}?query=${value.toLowerCase()}`)
        .then(() => window.scrollTo(0, 0));
    }
  };

  useOutsideClick(containerRef, handleBlur);

  return (
    <Container ref={containerRef}>
      <InputField
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        spellCheck={false}
        prefix={
          <IconSearchButtonWrapper>
            <Button onClick={goToSearchPage}>
              <Icons.Search />
            </Button>
          </IconSearchButtonWrapper>
        }
        suffix={
          value && focused ? (
            <Button onClick={handleClickClearInput}>
              <Icons.Cross />
            </Button>
          ) : null
        }
        placeholder="Waar ben je naar op zoek?"
        ref={ref}
      />
      {isAutoCompleteShown && (
        <AutoComplete>
          <ul>
            {data?.map((name: string) => {
              return (
                <li key={name}>
                  <Link href={`${APP_ROUTES.SEARCH}?query=${name.toLowerCase()}`} passHref>
                    <a>
                      <Icons.Search size="small" />
                      <span>{name}</span>
                    </a>
                  </Link>
                </li>
              );
            })}
          </ul>
        </AutoComplete>
      )}
    </Container>
  );
});
