import { device } from 'constants/device';

import styled from 'styled-components';
import {
  FavoriteButtonWrapper,
  ImageOverlay,
} from 'features/layout/components/Cards/common/Picture/Picture.styled';

export const Container = styled.div<{ horizontal: boolean }>`
  background-color: ${({ theme }) => theme.system.white};
  display: flex;
  flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.system.lightGrey};
  border-radius: ${({ horizontal }) => (horizontal ? '20px' : '18px')};
  width: ${({ horizontal }) => (horizontal ? '100%' : '295px')};
  height: ${({ horizontal }) => (horizontal ? '148px' : '296px')};
  position: relative;
  &:hover {
    border: 1px solid ${({ theme }) => theme.brand.blue};
    & ${ImageOverlay} {
      opacity: 0.3;
    }
    & ${FavoriteButtonWrapper} {
      display: inline-block;
    }
  }

  @media ${device.tablet} {
    width: 214px;
    height: 184px;
    border-radius: 8px;
    padding: 12px;
  }
`;

export const InfoContainer = styled.div<{ horizontal: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: ${({ horizontal }) => (horizontal ? '0 24px 0' : '12px 0 0')};
  height: 100%;
  width: 100%;
`;

export const DetailsContainer = styled.div<{ horizontal: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ horizontal }) => (horizontal ? 'space-between' : 'start')};
`;
export const TimeWrapper = styled.span`
  display: block;
  color: ${({ theme }) => theme.system.darkGreyActive};
  font-weight: 500;
  font-size: 1.4rem;
  @media ${device.tablet} {
    font-weight: 600;
    font-size: 1rem;
  }
`;

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  height: 100%;
  @media ${device.tablet} {
    margin-top: 8px;
  }
`;

export const Category = styled.span`
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 140%;
  color: ${({ theme }) => theme.system.darkGreyActive};
`;
