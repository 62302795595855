import { ParsedUrlQuery } from 'querystring';

import { isVerified, lifestyleType } from '@studenten/shared/constants/filters';
import { makeAutoObservable, runInAction } from 'mobx';
import { enableStaticRendering } from 'mobx-react';
import { useMemo } from 'react';
import { CancelTokenSource, fetcher } from 'api_entities/fetch';
import { createFilterItems, getKeyValue, getSearchParams } from 'lib/filters';
import { lifestyleApi } from 'api_entities/lifestyle';
import { FacetedResponse } from 'api_entities/types';
import { Lifestyle } from 'api_entities/lifestyle/types';
import { FilterItem } from 'lib/filters/types';

import { ILifestyleSearchStore } from './types';

enableStaticRendering(typeof window === 'undefined');

let source: CancelTokenSource;
let store: LifestyleSearchStore;
export class LifestyleSearchStore implements ILifestyleSearchStore {
  data: FacetedResponse<Lifestyle> = { data: [], totalCount: 0, totalFacets: {}, facets: {} };
  filters: ILifestyleSearchStore['filters'] = {
    isVerified: isVerified,
    type: lifestyleType,
  };
  constructor() {
    makeAutoObservable(this);
  }
  async deleteAllSelectedFilters(query: ParsedUrlQuery) {
    await this.fetchData(query, true);
    await this.fetchFilters(query, null);
  }
  async deleteSelectedFilter(
    query: ParsedUrlQuery,
    key: keyof ILifestyleSearchStore['filters'],
    value: string
  ) {
    const targetItem: FilterItem | undefined = getKeyValue(this.filters)(key).find(
      (item: FilterItem) => item.value === value
    );
    if (targetItem?.checked) {
      targetItem.checked = false;
    }
    await this.fetchFilters(query, key);
  }
  toggleFilters = (data: Array<FilterItem>, filterKey: keyof ILifestyleSearchStore['filters']) => {
    this.filters = {
      ...this.filters,
      [filterKey]: (this.filters[filterKey] as Array<FilterItem>).map((item, idx) => ({
        ...item,
        checked: data[idx].checked,
      })),
    };
  };
  async fetchFilters(
    query: ParsedUrlQuery,
    filterKey: keyof ILifestyleSearchStore['filters'] | null
  ) {
    try {
      if (filterKey) {
        await this.fetchData(query, true);
        Object.entries(this.filters).map(async ([key]) => {
          if (query[key]?.length && key !== 'page') {
            this.filters[key] = this.filters[key].map((subItem) => {
              return {
                ...subItem,
                counter: this.data.facets?.[key]?.[subItem.value] || 0,
              };
            });
          } else {
            this.filters[key] = this.filters[key].map((subItem) => {
              return {
                ...subItem,
                counter: this.data.totalFacets?.[key]?.[subItem.value] || 0,
              };
            });
          }
        });
      } else {
        this.filters = {
          isVerified: createFilterItems(
            this.filters.isVerified,
            'isVerified',
            this.data.totalFacets,
            this.data.facets,
            query
          ),
          type: createFilterItems(
            this.filters.type,
            'type',
            this.data.totalFacets,
            this.data.facets,
            query
          ),
        };
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }
  hydrate(
    initialData: ILifestyleSearchStore['data'],
    initialFilters: ILifestyleSearchStore['filters']
  ) {
    this.data = initialData;
    this.filters = initialFilters;
  }
  async fetchData(query: ParsedUrlQuery, resetPage?: boolean) {
    const params = getSearchParams(query, resetPage);
    if (source) {
      source.cancel('Operation canceled by the user.');
    }
    source = fetcher.CancelToken.source();
    const data = await lifestyleApi.get(params, source);
    runInAction(() => {
      this.data = data;
    });
  }
}

function initializeStore(
  initialData?: ILifestyleSearchStore['data'],
  initialFilters?: ILifestyleSearchStore['filters']
) {
  const _store = store ?? new LifestyleSearchStore();

  // If your page has Next.js data fetching methods that use a Mobx store, it will
  // get hydrated here

  if (initialData && initialFilters) {
    _store.hydrate(initialData, initialFilters);
  }
  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
}

export function useStore(
  initialData?: ILifestyleSearchStore['data'],
  initialFilters?: ILifestyleSearchStore['filters']
) {
  const store = useMemo(() => initializeStore(initialData, initialFilters), [
    initialData,
    initialFilters,
  ]);

  return store;
}
