import { FilterPageConfigItem } from 'lib/filters/types';

export const lifestyleFilters: Array<FilterPageConfigItem> = [
  {
    key: 'isVerified',
    title: 'HOT',
    type: 'filter',
  },
  {
    key: 'type',
    title: 'Type',
    minFiltersToShow: 5,
    type: 'filter',
  },
];
