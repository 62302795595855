import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  position: relative;
`;

export const AutoComplete = styled.div`
  position: absolute;
  width: 100%;
  top: 50px;
  background-color: ${({ theme }) => theme.system.white};
  border: 1px solid ${({ theme }) => theme.system.lightGrey};
  box-shadow: 0px 16px 16px -8px rgba(17, 17, 17, 0.07);
  border-radius: 6px;
  z-index: 2;
  svg {
    cursor: pointer;
  }
  ul {
    width: 100%;
    li {
      color: ${({ theme }) => theme.system.darkGreyActive};
      &:hover {
        background-color: ${({ theme }) => theme.system.lightGrey};
      }
      a {
        padding: 14px 24px;
        display: inline-block;
        width: 100%;
        white-space: nowrap;
        & > span {
          margin-left: 9px;
          display: inline-block;
          width: 95%;
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 1.2;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
`;

export const IconSearchButtonWrapper = styled.div`
  & > button {
    padding: 0;
  }
`;
