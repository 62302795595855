import { API_URL } from 'constants/routes';

import { FacetedResponse } from 'api_entities/types';
import { CancelTokenSource, fetcher } from 'api_entities/fetch';

import { Lifestyle } from './types';

export const lifestyleApi = {
  get: async function (params: URLSearchParams, cancelToken: CancelTokenSource) {
    const { data } = await fetcher.get<FacetedResponse<Lifestyle>>(API_URL.LIFESTYLE, {
      params,
      cancelToken: cancelToken.token,
    });
    return data;
  },
  getBySlug: async function (slug?: string) {
    const { data } = await fetcher.get<Lifestyle>(`companies/slug/${slug}`);
    return data;
  },
  getAllLifestyle: async function () {
    const params = new URLSearchParams();
    params.append('limit', '10000');
    const { data } = await fetcher.get<FacetedResponse<Lifestyle>>(
      `${API_URL.LIFESTYLE}/site_map`,
      {
        params: params,
        headers: {
          'x-token-token': process.env.NEXT_PUBLIC_SITEMAP_TOKEN || '',
        },
      }
    );
    return data;
  },
};
