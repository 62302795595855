import { size } from 'constants/device';

import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useRouter } from 'next/router';
import ReactPaginate from 'react-paginate';
import { Button, Icons } from '@studenten/ui-components';
import { useWindowSize } from 'features/layout/hooks/useWindowSize';

import { Container } from './Pagination.styled';

export type Props = {
  shallow?: boolean;
  elementsToShow?: number
  totalCount: number;
};

export const Pagination = ({ shallow = false, totalCount, elementsToShow = 5 }: Props) => {
  const router = useRouter();
  const themeContext = useContext(ThemeContext);
  const { width: windowWidth } = useWindowSize();
  const isMobile = windowWidth < size.tablet;

  const handleChangePage = (page: number): void => {
    if (typeof window !== 'undefined') {
      const searchSection = document.getElementById('searchResults');
      if (searchSection?.offsetTop) {
        window?.scrollTo(
          0,
          searchSection?.offsetTop - parseInt(themeContext.dimensions.headerHeight)
        );
      }
      const params = new URLSearchParams(window.location.search);
      params.set('page', String(page + 1));
      router.push(`${window.location.pathname}/?${params.toString()}`, undefined, {
        shallow,
      });
    }
  };

  const getInitialPage = (): number => {
    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(window.location.search);
      if (params.has('page')) {
        return Number(params.get('page')) - 1;
      }
    }
    return 0;
  };

  if (typeof window !== 'undefined') {
    const hrefBuilder = (page: number): string => {
      return `${window.location.pathname}?page=${page}`;
    };

    return (
      <Container>
        <ReactPaginate
          onPageChange={({ selected }) => handleChangePage(selected)}
          forcePage={getInitialPage()}
          disableInitialCallback
          pageCount={Math.ceil(totalCount / elementsToShow)}
          pageRangeDisplayed={isMobile ? 1 : 3}
          marginPagesDisplayed={isMobile ? 1 : 3}
          previousLabel={
            <Button variant="base">
              <Icons.LeftArrow />
            </Button>
          }
          nextLabel={
            <Button variant="base">
              <Icons.RightArrow />
            </Button>
          }
          activeLinkClassName="activeLink"
          hrefBuilder={hrefBuilder}
        />
      </Container>
    );
  }
  return null;
};
