import { FilterItem } from 'lib/filters/types';

export const getMetaTags = (typeFilters: Array<FilterItem>) => {
  const selectedFilterType = typeFilters.find((item) => item.checked);

  switch (selectedFilterType?.value) {
    case 'Gym and Sports': {
      return {
        title: 'Al het sportaanbod voor studenten vind je hier op Studenten.nl',
        description:
          'Al het sport aanbod en sportscholen voor studenten vind je hier op de website van Studenten.nl.',
      };
    }

    case 'Cafe and Restaurants': {
      return {
        title: 'Alles cafés en restaurants met speciale studentenacties - Studenten.nl',
        description:
          'Alles cafés en restaurants met speciale studentenacties vind je hier op de website van Studenten.nl.',
      };
    }

    case 'Libraries and Bookshops': {
      return {
        title: 'Boekwinkels en bibliotheken vind je hier op Studenten.nl',
        description:
          'Zoek jij een bibliotheek of boekwinkel. Vergelijk ze allemaal op de website van Studenten.nl.',
      };
    }

    case 'Clothing and Shoes': {
      return {
        title: 'Kledingwinkels en aanbieidngen voor studentne - Studenten.nl',
        description:
          'Alle kledingwinkels met speciale studentenaanbiedingen vind je hier op Studenten.nl.',
      };
    }

    case 'Household stuff / In rooms': {
      return {
        title: 'Alles voor je studentenkamer vind je hier op Studenten.nl',
        description:
          'Alles voor je studentenkamer vind je hier. Studentenacties, meubels en aanbiedingen voor de studentenwoning.',
      };
    }

    case 'Healthcare': {
      return {
        title: 'Studentenhuisarts, tandartsen, verzekeringen en meer - Studenten.nl',
        description:
          'Vind de beste studentenhuisarts, tandarts, ggd of andere gezondheidsaanbieder nodig? Vind ze allemaal hier op Studenten.nl.',
      };
    }

    case 'Culture': {
      return {
        title: 'Studenten cultuur vergelijker - Studenten.nl',
        description: 'Vind alles voor en omtrent cultuur in jouw studentenstad op Studenten.nl.',
      };
    }

    case 'Copyshops': {
      return {
        title: 'Copyshop voor studenten - Studenten.nl',
        description: 'Vind alle copyshops op Studententen.nl.',
      };
    }

    case 'Driving schools': {
      return {
        title: 'Rijscholen voor studenten - Studenten.nl',
        description: 'Vind alle rijscholen voor studenten op de website van Studenten.nl.',
      };
    }

    case 'Cinema': {
      return {
        title: 'Bioscopen met studentenaanbiedingen - Studenten.nl',
        description: 'Vind alle bioscopen met studenten aanbiedingen op Studenten.nl.',
      };
    }

    default: {
      return {
        title: 'Vind de leukste bedrijven voor jouw studenten lifestyle op Studenten.nl',
        description:
          'Leuke lifestyle bedrijven, evenmenten en aanbiedingen vind je hier op de website van Studenten.nl.',
      };
    }
  }
};
