import { size } from 'constants/device';
import { APP_ROUTES } from 'constants/routes';
import { LIFESTYLE_TYPES } from 'constants/lifestyle';

import React from 'react';
import Link from 'next/link';
import Location from 'features/layout/components/Cards/common/Location';
import Title from 'features/layout/components/Cards/common/Title';
import Picture from 'features/layout/components/Cards/common/Picture';
import { useFavorite } from 'hooks/useFavorite';
import { useWindowSize } from 'features/layout/hooks/useWindowSize';

import placeholder7 from '../../../../../public/assets/placeholders/placeholder-7.svg';
import placeholder5 from '../../../../../public/assets/placeholders/placeholder-5.svg';
import placeholder1 from '../../../../../public/assets/placeholders/placeholder-1.svg';

import {
  Container,
  DetailsContainer,
  GroupContainer,
  InfoContainer,
  Category,
  TimeWrapper,
} from './StyledLifestyleCard';
import type { Props } from './types';

const redirectToCategory = (type: string) => {
  if (type === LIFESTYLE_TYPES.HEALTH_CARE) {
    return `${APP_ROUTES.LIFESTYLE}${APP_ROUTES.HEALTH}`;
  } else if (type === LIFESTYLE_TYPES.GYM_AND_SPORTS) {
    return `${APP_ROUTES.LIFESTYLE}${APP_ROUTES.SPORT}`;
  } else if (type === LIFESTYLE_TYPES.STUDENT_HOUSING) {
    return APP_ROUTES.STUDENT_HOUSING;
  } else if (type === LIFESTYLE_TYPES.STUDENT_SOCIETY) {
    return APP_ROUTES.STUDENT_SOCIETIES;
  } else {
    return `${APP_ROUTES.LIFESTYLE}${APP_ROUTES.ACTIVITY}`;
  }
};

const favoriteType = (type: string) => {
  if (type == LIFESTYLE_TYPES.STUDENT_SOCIETY) {
    return 'student_society';
  } else if (type == LIFESTYLE_TYPES.STUDENT_HOUSING) {
    return 'student_housing';
  } else {
    return 'local_business';
  }
};

const getPlaceholder = (type?: string) => {
  if (type === LIFESTYLE_TYPES.GYM_AND_SPORTS) return placeholder7;
  if (type === LIFESTYLE_TYPES.HEALTH_CARE) return placeholder7;
  if (type === LIFESTYLE_TYPES.STUDENT_HOUSING || type === LIFESTYLE_TYPES.STUDENT_SOCIETY)
    return placeholder5;
  if (type === LIFESTYLE_TYPES.CAFE_AND_RESTAURANTS) return placeholder1;
};

export const LifestyleCard = ({
  title,
  type,
  city,
  photoURL,
  horizontal,
  objectID,
  slug,
  cityCard,
  openingHours,
}: Props) => {
  const [isFavorite, onFavoriteClick] = useFavorite(favoriteType(type), objectID);
  const { width } = useWindowSize();
  const isMobile = width < size.tablet;
  const mainCity = Array.isArray(city) ? city[0] : city;

  const groupRenderer = () => {
    if (type === 'Healthcare' && cityCard && openingHours && openingHours[0])
      return (
        <TimeWrapper>
          {openingHours[0]} - {openingHours[1]}
        </TimeWrapper>
      );
    if (type === 'Gym and Sports' && cityCard && openingHours && openingHours[0])
      return (
        <TimeWrapper>
          {openingHours[0]} - {openingHours[1]}
        </TimeWrapper>
      );
    if (cityCard) return <TimeWrapper>{type}</TimeWrapper>;
    return <GroupContainer>{horizontal && <Category>{type}</Category>}</GroupContainer>;
  };
  return (
    <Link href={`${redirectToCategory(type)}/${slug}`} passHref>
      <a>
        <Container horizontal={horizontal}>
          <Picture
            src={photoURL}
            height={isMobile ? '90px' : cityCard ? '146px' : '100px'}
            width={horizontal ? '271px' : '100%'}
            alt="Company"
            isFavorite={isFavorite}
            onFavoriteClick={onFavoriteClick}
            fallBackSRC={getPlaceholder(type)}
            loading="lazy"
          />
          <InfoContainer horizontal={horizontal}>
            <Title maxLineCount={2}>{title}</Title>
            <DetailsContainer horizontal={Boolean(horizontal || cityCard)}>
              {groupRenderer()}
              <Location name={mainCity} />
            </DetailsContainer>
          </InfoContainer>
        </Container>
      </a>
    </Link>
  );
};

LifestyleCard.defaultProps = {
  horizontal: false,
};
