import { device } from 'constants/device';

import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  button {
    border-radius: 8px;
    height: 54px;
    width: 54px;
    @media ${device.tablet} {
      height: 40px;
      width: 40px;
    }
  }
  & > ul {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.4rem;
    text-align: center;
    color: ${({ theme }) => theme.system.darkGreyActive};
    @media ${device.tablet} {
      font-size: 1.4rem;
      line-height: 1.7rem;
    }
  }
  a.activeLink {
    color: ${({ theme }) => theme.brand.blue};
    outline: none;
  }
  li {
    height: 32px;
    display: flex;
    align-items: center;
    margin: 0 8px;
    background: ${({ theme }) => theme.system.white};
    border: 1px solid ${({ theme }) => theme.system.darkGreyActive};
    border-radius: 4px;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;

    @media ${device.tablet} {
      height: 44px;
    }
    & > a {
      @media ${device.tablet} {
        padding: 10px;
        display: flex;
      }
    }
  }
  li.selected {
    border: 1px solid ${({ theme }) => theme.brand.blue};
  }
  li.disabled{
    display: none
  }
  li.previous,
  li.next {
    border: none;
    background: transparent;
    & > a {
      padding: 0;
      button {
        margin: 0;
      }
    }
  }
  button:first-child {
    margin-right: 28px;
    @media ${device.tablet} {
      margin-right: 18px;
    }
  }
  button:last-child {
    margin-left: 28px;
    @media ${device.tablet} {
      margin-left: 18px;
    }
  }
  a {
    padding: 0px 12px;
  }
`;
