import { API_URL } from 'constants/routes';

import { CancelTokenSource, fetcher } from 'api_entities/fetch';

let source: CancelTokenSource;

export const autocompleteApi = {
  getSuggestions: async (value: string): Promise<string[]> => {
    if (source) {
      source.cancel('Operation canceled by the user.');
    }
    source = fetcher.CancelToken.source();
    const params = new URLSearchParams();
    params.append('limit', String(10));
    params.append('query', value);
    const { data } = await fetcher.get(API_URL.AUTOSEARCH, { params, cancelToken: source.token });
    return data;
  },
};
